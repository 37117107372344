import React, {
  forwardRef,
  useImperativeHandle
} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
import config from '../../lib/config';
import { Link } from "react-router-dom";


  export const Share = forwardRef((props, ref) => {


    const [item, Set_Item] = React.useState({});
    const [onwer_price, set_onwer_price] = React.useState({});

    useImperativeHandle(
      ref,
      () => ({
          async ShareSocial_Click(items, onwer_price) {
              if (items) {
                  Set_Item(items)
                  set_onwer_price(onwer_price)
                  window.$('#shareicons').modal('show');

              }
          }
      }),
  )
   
return(
    
    <>
<div className="modal fade" id="shareicons" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content Share_modle">
      <div class="modal-header Share_modle_header">
        <h5 class="modal-title" id="staticBackdropLabel">Share Link</h5>
      
      </div>

<div className="modal-body Share_modle_body">
<div className='shareiconslinkss mt-4 mb-4'>
<div className='row'>


    <div className="col-3 col-sm-3 col-lg-3">
        <div className="text-center icon_div">

            <TwitterShareButton
                title={`${item.tokenName}  NFT`}
                url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
              
            >
                <i className="fab fa-twitter"></i>
               

            </TwitterShareButton>

        </div>
    </div>
    <div className="col-3 col-sm-3 col-lg-3">
        <div className="text-center icon_div">

            <TelegramShareButton
                title={`${item.tokenName}  NFT`}
                url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
            
            >

                <i className="fab fa-telegram-plane"></i>
               

            </TelegramShareButton>

        </div>
    </div>
    <div className="col-3 col-sm-3 col-lg-3 ">
        <div className="text-center icon_div">
            <FacebookShareButton
                quote={`${item.tokenName} NFT`}
                url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
                        >
                <i className="fab fa-facebook-f"></i>
             
            </FacebookShareButton>

        </div>
    </div>
    <div className="col-3 col-sm-3 col-lg-3">
        <div className="text-center icon_div">
            <WhatsappShareButton
               title={`${item.tokenName}  NFT`}
               url={`${config.Front_URL}/info/${onwer_price.tokenOwner}/${item.contractAddress}/${onwer_price.tokenCounts}` + " " +`${config.shareTag[0]}`+" "+`${config.shareTag[1]}` +" " +`${(config.shareTag[2])}`}
             >
                <i className="fab fa-whatsapp"></i>
                
            </WhatsappShareButton>

        </div>
    </div>
    </div>
</div>
</div>





      <div class="modal-footer Share_modle_footer">
        <Link class="bidbtn" data-bs-dismiss="modal">Cancel</Link>
        
      </div>
    </div>

    
  </div>
</div>

    </>
 )
})