import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Header from "../components/Header/Head";
import Footer from "../components/Footer/Footer.js";
import ReactHtmlParser from "react-html-parser";
import { faqlists } from "../actions/v1/report";
import "./termsOfService.css";
import termsBannerImg from "../assets/Pages/Terms-and-service/bannerImage.svg";
import termsBannerStar from "../assets/Pages/Terms-and-service/yellowstar.svg";
import termsAccordianStars from "../assets/Pages/Terms-and-service/accordianStar.svg";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Privacypolicy(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { ...rest } = props;
  const [eventkey, setEventkey] = useState(0);
  const [faqlist, setfalist] = useState([]);
  const [TopContent, setTopContent] = useState([]);

  useEffect(() => {
    faqlistfunc();
  }, [eventkey]);
  const faqlistfunc = async () => {
    var fql = await faqlists({ dbname: "terms" });

    if (fql && fql.data && fql.data.soci) {
      setfalist(
        fql?.data?.soci?.length > 0 &&
          fql?.data?.soci?.map((ite) => (ite?.TopContent ? false : ite))
      )?.filter((ite) => ite && ite);

      setTopContent(
        fql?.data?.soci?.length > 0 &&
          fql?.data?.soci
            ?.map((ite) => (ite?.TopContent ? ite.TopContent : false))
            ?.filter((ite) => ite && ite)[0]
      );
    }
  };

  return (
    <div className="inner_header otherpage">
      <Header />
      <ScrollToTopOnMount />
      <div className="container-fluid p-0 termsBanner">
        <div className="termsofservice">
          <div className="termstextBox">
            <img src={termsBannerStar} alt="termsBannerStar" id="termsBannerStar" />
            <h1><span id="yellowHeadText">Terms</span> of Service</h1>
            <h4>Updated August 31st, 2023</h4>
          </div>
          <div className="termsimageBox">
            <img src={termsBannerImg} alt="termsBannerimage" id="termsimageBox" />
          </div>
        </div>
      </div>
      <div className="termsbanner_bgcolor">
        <div className="container-fluid custom-container terms-sevice-content">
          <div className="termsofservice">
            <div className="disc_accord pt-5">
              {ReactHtmlParser(TopContent)}
            </div>

            <Accordion className="accordionaccordion">
              <div className="terms-accordian-stars" style={{justifyContent:"end"}}>
                <img src={termsAccordianStars} alt="termsAccordianStars" />
              </div>
              {faqlist.length > 0 &&
                faqlist
                  .filter((ite) => ite && ite)
                  .map((data, key) => {
                    return (
                      <Accordion.Item
                        eventKey={JSON.stringify(key)}
                        className="tf-toggle disc_accord "
                      >
                        <Accordion.Header className="tf-toggle-title robotoReg">
                          <h6>{data.question}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="robotoReg faq_anstxtclr"
                            dangerouslySetInnerHTML={{ __html: data.answer }}
                          ></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
            </Accordion>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}