import React, { useEffect, useState } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Accordion from "react-bootstrap/Accordion";
import Profile from "../assets/images/CLIENT/faq.webp";
import { faqlists } from "../actions/v1/report";
import termsAccordianStars from "../assets/Pages/Terms-and-service/accordianStar.svg";
import "./faq.css";
import SearchBar from "./search_bar.js";
import purplestars from "../assets/images/faqpurplestar.svg";
import circleImagee from "../assets/images/circleImagee.svg";
import blogContentData from "../assets/blogsContent.js";

export default function Faq(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [eventkey, setEventkey] = useState(0);
  const { ...rest } = props;
  const [faqlist, setfalist] = useState([]);

  useEffect(() => {
    faqlistfunc();
  }, [eventkey]);
  const faqlistfunc = async () => {
    var fql = await faqlists({ dbname: "faq" });
    if (fql && fql.data && fql.data.soci) {
      setfalist(fql.data.soci);
    }
  };
  return (
    <>
      <div className="explorepage faq otherpage">
        <Header />
        <div className="container-fluid p-0 faqBanner">
          <div className="termsofservice">
            <div className="termstextBox">
              <img id="leftPurpleStar" src={purplestars} alt=""/>
              <img id="rightPurpleStar" src={purplestars} alt=""/>
              <h1>How can we help?</h1>
            </div>
            <div className="searchWraper">
              <SearchBar />
              <div className="eclipseWrapper">
                <img id="circleImagee" src={circleImagee} alt=""/>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid FeaturedSupportArticles">
          <div className="custom-container container-fluid">
            <h2>Featured Support Articles</h2>
            <div className="blogsWrapper">
              {blogContentData &&
                blogContentData.map((item) => {
                  return (
                    <div key={item.id}>
                      <a href={item.link} className="blog_image" target="_blank" rel="noreferrer">
                        <img src={item.image} alt="circleImge" />
                      </a>
                      <div className="blogsWrapperContent">
                        <h4>{item.title}</h4>
                        <p>{item.decription}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="faqbanner_bgcolor">
          <div className="container-fluid custom-container faq-content">
            <div className="faq_para pt-5">
              <div className="tf-accordion">
                <Accordion>
                  <div className="terms-accordian-stars">
                    <div className="faqLeftBox">
                      <h2>FAQ</h2>
                    </div>
                    <div className="faqRightBox">
                      <img
                        src={termsAccordianStars}
                        alt="termsAccordianStars"
                      />
                    </div>
                  </div>
                  {faqlist.length > 0 &&
                    faqlist.map((data, key) => {
                      return (
                        <Accordion.Item
                          eventKey={JSON.stringify(key)}
                          className="tf-toggle"
                        >
                          <Accordion.Header className="tf-toggle-title robotoReg">
                            <b>{data.question}</b>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div
                              className="robotoReg faq_anstxtclr disc_accord"
                              dangerouslySetInnerHTML={{
                                __html: data.answer,
                              }}
                            ></div>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                </Accordion>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}