import image1 from "./myAccountimg.svg"
export const SalesItemData = [
  {
    id: 1,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
  {
    id: 2,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
  {
    id: 3,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
  {
    id: 4,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
  {
    id: 5,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
  {
    id: 6,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
  {
    id: 7,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
  {
    id: 8,
    title: "Chrome Browser",
    price: "0.00 Cake",
    Image: image1,
  },
];
