import firstBlogImage from "../assets/Pages/Faq/firstBlog.png"
import secondBlogImage from "../assets/Pages/Faq/secondBlog.png"
import thiredBlogImage from "../assets/Pages/Faq/thiredBlog.jpg"

const blogContentData = [
  {
    id: 1,
    title: "How are Data NFTs helping build a decentralized data economy?",
    decription:
      "Data NFTs can revolutionize the data brokerage industry by offering transparency, control and monetization possibilities for users.",
      image:`${firstBlogImage}`,
      link:"https://cointelegraph.com/news/how-are-data-nfts-helping-build-a-decentralized-data-economy"
  },
  {
    id: 1,
    title: "Nokia announces blockchain-powered data marketplace",
    decription:
      " Nokia’s permissioned blockchain platform will reportedly allow trusted data exchange and monetization among client participants.",
      image:`${secondBlogImage}`,
      link:"https://cointelegraph.com/news/nokia-announces-blockchain-powered-data-marketplace"
  },
  {
    id: 1,
    title: "Simon Data Launches NFT Platform Allowing Brands To Finally Own Their Customer Data",
    decription:
      " NEW YORK – In a groundbreaking move, Simon Data, a customer data platform designed to let marketers easily build personalized cross-channel customer journeys, has launched a decentralized platform allowing consumer brands to finally, literally, own their customer data – as a Non-Fungible Token (or NFT).",
      image:`${thiredBlogImage}`,
      link:"https://www.simondata.com/resources/simon-data-launches-nft-platform-allowing-brands-to-finally-own-their-customer-data/"
  },
];

export default blogContentData;
