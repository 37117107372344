import axios from "axios";
import config from '../../lib/config';
import Multiple from '../../ABI/MULTIPLE.json';
import { decodedata, encodedata } from "../../views/Entryption";

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const CancelBid_Action = async (payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/bid/cancel`,
      'headers': {
        'Authorization': localStorage.user_token
    },
      data: payload
    });
    ToastShow(Resp.data);
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const ToastShow = async (data) => {
  if (data.toast && data.toast.type && data.toast.msg) {
    if (data.toast.type === 'success') {
      toast.success(data.toast.msg, toasterOption)
    } else {
      toast.error(data.toast.msg, toasterOption)
    }
  }
}
export const getListOfToken = async () => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/tokenOption`,
    });
    return {
      data: respData

    }
  }
  catch (err) {
  }
}

export const GetLikeDataAction = async (payload) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/like/list`,
      data: payload
    });
    return {
      data: respData.data
    }
  }
  catch (err) {
  }
}

export const AddLikeAction = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/like`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}




export const CollectiblesList_Home = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/collectibles/list/home`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    
  }
}

export const WenlamboConvert = async (data) => {
  //console("datra" + JSON.stringify(data))
  var url = `https://min-api.cryptocompare.com/data/price?fsym=${data}&tsyms=USD`
  // var url = `https://api.pancakeswap.info/api/v2/tokens/${data}`
  try {
    let respData = await axios({
      'method': 'get',
        //  url: `https://api.pancakeswap.info/api/v2/tokens/${data}`,
      // url: `https://min-api.cryptocompare.com/data/${data}`,
        'url': url
    });
    //console("get val",respData);
    return {

      data: respData.data
    }
  }
  catch (err) {
    return {
      // error: err.response.data
    }
  }
}

export const likes = async(payload)=>
{
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/likes`,
      "data":payload
    });
    return {
      data: respData
    }
  }
  catch (err) {
  }
}




export const getAllCounts = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getAllNFTCounts`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}






export const CollectiblesList_MyItems = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/collectibles/list/myitems`,
      params: payload
    });

    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}



export const TokenCounts_Get_Detail_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/tokenCounts`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const setTokenCounts_Get_Detail_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/settokenCounts`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const History = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/History`,
      params: payload
    });
    // 
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const checkOtherPlatformDetais1155 = async (item, itemCur, type, web3) => {
  try {
    if(web3)
    {
    var currentOwner = itemCur.tokenOwner;
    var tokenCounts = itemCur.tokenCounts;
    var contractAddress = itemCur.contractAddress
    var owner = null;
    var balance = null;

    if (type === 1155) {
      try{
        
      var CoursetroContract = new web3.eth.Contract(
        Multiple,
        contractAddress
      );

      balance = await CoursetroContract.methods
        ._unblockBalances(
        tokenCounts,
        currentOwner
        ).call()
        }catch(err)
        {
        }
      }


    return balance;
  }
}
  catch (e) {
   
  }

}

const findAndUpdateBalance = async (payload) => {
  ////console('>>>>>>>>payload',payload);
  var data = await findAndUpdateBalanceAction(payload);
  if (data && data.data) {
    ////console('>>>>>data', data.data.success);
  }
}

const findAndUpdateBalanceAction = async (payload) => {
  try {
    let resp = await axios({
      method: 'post',
      url: `${config.vUrl}/token/findAndUpdataBalance`,
      'headers': {
        'Authorization': localStorage.user_token
    },
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    ////console(">>>>fberror",err);
    return {
      data: [],
      error: 'Error : Oops something went to wrong'
    }
  }
}

export const PurchaseNow_Complete_Action = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/purchase/complete`,
      'headers': {
        'Authorization': localStorage.user_token
    },
      data: payload
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const PurchaseNow_Complete_Action_Meta = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/updateusermeta`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const PurchaseNow_Price_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/price/addprice`,
      'headers': {
        'Authorization': localStorage.user_token
    },
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const convertionValue = async (data) => {

  try {
    let respData = await axios({
      'method': 'get',
      'url': 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD',

    });
    return {

      data: respData.data
    }
  }
  catch (err) {
    return {
    }
  }
}

export const BidApply_ApproveAction = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/bid/apply`,
      'headers': {
        'Authorization': localStorage.user_token
    },
      data: payload
    });
    resp.data = decodedata(resp.data);
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}
export const acceptBId_Action = async (payload) => {
  try {

    payload = { encode: encodedata(payload) };

    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/bid/accept`,
      'headers': {
        'Authorization': localStorage.user_token
    },
      data: payload
    });
    Resp.data = decodedata(Resp.data);

    ToastShow(Resp.data);
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const subdetail = async (payload) => {
  try {
    payload = { encode: encodedata(payload) };
    let Resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/subdetail`,
      'headers': {
        'Authorization': localStorage.user_token
    },
      data: payload
    });
    Resp.data = decodedata(Resp.data);
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const getsubscribe = async (payload) => {
  try {
    let Resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getsubscribe`,
      'headers': {
        'Authorization': localStorage.user_token
    },
    params: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}


export const getunlockdatas = async (payload,authtoken) => {
  try {
    let Resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getdailyData`,
      'headers': {
        'Authorization': authtoken
    },
    params: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const TokenPriceChange_update_Action = async (payload) => {
  try {
 payload = { encode: encodedata(payload) };
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/price/change`,
      'headers': {
        'Authorization': localStorage.user_token
      },
      data: payload
    });
    resp.data = decodedata(resp.data);

    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const tokenownerentry=async(payload)=>
{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/tokenownerentry`,
     
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const liveaution =async(payload)=>
{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/liveaution`,
    
      data: payload
    });

    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const getpromotiondata =async(payload)=>
{
  try {
    let Resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getpromotiondata`,
      'headers': {
        'Authorization': localStorage.user_token
    },
    params: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}


export const kyctokencall = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/kyctokencall`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    
  }
}

export const KYBcall = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/KYBcall`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    
  }
}

export const datacheck =async(payload)=>
{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/datacheck`,
      // 'headers': {
      //   'Authorization': localStorage.user_token
      // },
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const getEvent =async()=>
{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/getEvent`,
      'headers': {
        'ComplyCube-Signature':"ComplyCube-Signature"
      },
      
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const KYBCheck =async(data)=>
{
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.vUrl}/token/KYBCheck`,
      'headers': {
        'ComplyCube-Signature':"ComplyCube-Signature"
      },
      data:data
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const getSubDetail = async (payload) => {
  try {
    let resp = await axios({
      'method': 'get',
      'url': `${config.vUrl}/token/getSubDetail`,
      params: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
    
  }
}

export const getTodaySubAmt = async (payload) => {
  try {
    const usdAmount = config.subscribeAmount;
    var wethAmount = await calculateWethAmount(usdAmount)
      return wethAmount.toFixed(6)
  }
  catch (err) {
    
  }
}

async function getWethPrice() {
  try {
    const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=weth&vs_currencies=usd');
    const wethPrice = response.data.weth.usd;
    return wethPrice;
  } catch (error) {
    console.error('Error fetching WETH price:', error);
    return null;
  }
}

async function calculateWethAmount(usdAmount) {
  const wethPrice = await getWethPrice();
  if (wethPrice !== null) {
    
    const wethAmount = usdAmount / wethPrice;
    return wethAmount;
  }
  return null;
}


