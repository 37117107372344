import React, { useState,useEffect } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Banner from "../assets/images/nft_cover.svg";
import Camera from "../assets/images/camera.png"
import Mail from "../assets/images/mail.png"
import User from "../assets/images/user.png"
import Profile from "../assets/images/png_unblk/defaultProfile.png"
import { useSelector } from "react-redux";
import config from "../lib/config";
import isEmpty from "../lib/isEmpty";
import { editprofile, getprofile } from "../actions/v1/user";
import {useHistory } from "react-router-dom";
import { toast } from "react-toastify";
toast.configure();
let toasterOption = config.toasterOption;
const initialFormValue = {
    name: "",
    customurl: "",
    bio: "",
    twitter: "",
    photo: "",
    cover:"",
    email: "",
    instagram: "",
    Portfolio:"",
    walletAddress:"",
  };
export default function Editprofile(){

  useEffect(() => {
    window.scrollTo(0,0); 
},[]);
    const Wallet_Details = useSelector((state) => state.wallet_connect_context);
    const history = useHistory();
    const [validateError, setValidateError] = useState({});
    const [file, setFile] = useState();
    const [disablebtn, setDisablebtn] = useState(0);
    const [imageVal, setImageVal] = useState("");
    const [coverVal, setcoverVal] = useState("");
    const [onchangeimg, setOnchangeimg] = useState(Profile);
    const [ImageVal, setImageVal1] = useState("");
    const [formValue, setFormValue] = useState(initialFormValue);
    const [coverimg, setcoverimg] = useState(Banner);
    const [twittercheck, settwittercheck] = useState(false);
    const [youtubecheck, setyoutubecheck] = useState(false);
    const [facebookcheck, setfacebookcheck] = useState(false);
    const [instagramcheck, setinstagramcheck] = useState(false);
    const [Ids, setIds] = useState("");
    const { name, customurl, bio, twitter, photo, cover, instagram, email, walletAddress, Portfolio } = formValue;
      useEffect(() => { getProfiledata(); }, [Wallet_Details.UserAccountAddr]);


      const editprofileUI = async (data) => {
        var validateError = {};
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
        let imageSize = 5000000;
        let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/;
        let urlRegex = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/;    
        if (onchangeimg !== "") {
          if (data.imageVal !== "") {
           
            if (
              !/\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|WEBP|gif|GIF)$/i.test(
                data.imageVal.name
              )
            ) {
              validateError.image =
                "file is invalid. only allowed JPG,PNG,WEBP,gif";
            }
          }
        }
        if (data.name === "") {
          validateError.names = "Name is Required";
        }
        if (data.name !== "") {
          if (config.nameFormat.test(data.name)) {
            validateError.names = "Cannot allowed smiley";
          }
        }
     
        if (data.customurl !== "") {
          if (config.nameFormat.test(data.customurl)) {
            validateError.customurl = "Cannot allowed smiley";
          }
        }
        if (data.customurl !== "") {
          if (urlRegex.test(data.customurl)) {
            validateError.customurl = "invalid custom url";
          }
        }
        if (data.email === "") {
          validateError.email = "Email is Required";
        }
        if (data.email !== "") {
          if (!emailRegex.test(data.email)) {
            validateError.email = "Invalid Email format";
          }
        }
    
        
        setValidateError(validateError);
        return validateError;
      };


async function getProfiledata() {
    setDisablebtn(0);

    const currAddr = Wallet_Details.UserAccountAddr;
    
    if(currAddr)
    {
    let reqData = {
      currAddr,
    };
    var data = await getprofile(reqData);
    if (data && data.userValue !== undefined) {
      let formdata = {};
      
      if (data?.userValue?.image !== "") {
        var profileimage = config.Back_URL +"/images/" +data?.userValue?._id + "/" +data?.userValue?.image;
        let coverimage = `${config.Back_URL}/images/coverimages/${data?.userValue?._id}/${data?.userValue?.coverimage}`;
        
        setcoverimg(coverimage)
        setOnchangeimg(profileimage);
        setIds(data?.userValue?._id);
      } else {
      }
      if (data?.userValue) {
        formdata["photo"] = data?.userValue?.image;
        formdata["bio"] = data.userValue?.bio;
        formdata["curraddress"] = data.userValue?.curraddress;
        formdata["customurl"] = data.userValue?.customurl;
        formdata["twitter"] = data.userValue?.twitter;
        formdata["name"] = data.userValue?.name;
        formdata["email"] = data.userValue?.email;
        formdata["youtube"] = data.userValue?.youtube;
        formdata["instagram"] = data.userValue?.instagram;
        formdata["facebook"] = data.userValue?.facebook;
        formdata["cover"] = data.userValue?.coverimage;
        formdata["Portfolio"] = data.userValue?.portfolio;

        formdata["walletAddress"] = data.userValue?.walletAddress;

      
        setFormValue(formdata);
        setinstagramcheck(data.userValue?.instagramcheck);
        setfacebookcheck(data.userValue?.facebookcheck);
        settwittercheck(data.userValue?.twittercheck);
        setyoutubecheck(data.userValue?.youtubecheck);
        //
      }
    } 
  }
  }


  const handleFile1 = (event) => {
    
    setDisablebtn(0);
    event.preventDefault();
    var reader = new FileReader();
    const { id, files } = event.target;
    setDisablebtn(0);
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      
      var url = reader.readAsDataURL(file);
      setcoverVal(file)
        
        reader.onloadend = function (e) {
            

            if (reader.result) {
                

                setcoverimg(reader.result)

            }
            window.$('.modal').modal('hide')

          };
    
    }
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
  };

  const handleFile = (event) => {
    setDisablebtn(0);
    event.preventDefault();
    var reader = new FileReader();
    const { id, files } = event.target;
    setDisablebtn(0);
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      
    
      setImageVal(file);
      var url = reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (reader.result) {
          setOnchangeimg(reader.result);
          setImageVal1(file.name);
        }
      };
    
    
    }
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
  };

  const onChange = (e) => {
   
    const { id, value } = e.target;   
      setDisablebtn(0); 
      e.preventDefault();
      let formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
  };

  const handleFormSubmit = async (e) => {


    
    const currAddr = Wallet_Details.UserAccountAddr;
    let reqData = { Portfolio,walletAddress, cover,imageVal, name,customurl, bio,twitter, photo,email, instagram,currAddr,facebookcheck,instagramcheck, youtubecheck,twittercheck };
    const custUrl = String(customurl).replace(/\s+/g, "").trim().toLowerCase();

    let reqData1 = {Portfolio,walletAddress, cover,imageVal,file, name, customurl: custUrl, bio,twitter, photo, email,instagram,currAddr, facebookcheck,  instagramcheck, youtubecheck, twittercheck
    };
    
    //editprofileUI(reqData)
    var errorUI = await editprofileUI(reqData);
    
    if (isEmpty(errorUI)) {
      
      var errors = await editprofile(reqData1);
    
      if (isEmpty(errors.error) || !isEmpty(errors.userValue)) {
        setDisablebtn(3);
        setDisablebtn(0);
        setValidateError("");
        toast.success("Profile Updated", toasterOption);
        setTimeout(() => history.push("/my-account"), 3000);
        setFormValue({ email: '', name: ''});
        setValidateError({});
      } else {
        
        setDisablebtn(1);
      
        
        setValidateError(errors);
      }
    }
     else {
      setDisablebtn(1)
      setValidateError(errorUI);
    }
  
  };


    return(
        <div>
         <div className="editprofile otherpage" >
        <Header />
      <div className="editprofilesec">
      <div className="container-fluid custom-container pb-5">
        <h1 className="robotoBold">Edit Profile</h1>
        <div className="editprofilecontent">
            <div className="editprofilebanner">
                <img src={coverimg} />
                <h3 className="editprofileedit" data-bs-toggle="modal" data-bs-target="#editprofile"><img src={Camera} /></h3>
            </div>
          
            <div className="editprofileprofile">
              <div className="before_inter">
              <img src={onchangeimg} />
              
              <div className="filetupepend">
                <i class="fas fa-pencil-alt"></i>
              <input type="file" id ="photo"onChange={handleFile}/>
              </div>
              
              </div>
              <div className="editprofilepage ml-3">
                <p className="mb-0">&nbsp;</p>
        <h2>Profile</h2>
        <h4 className="text-left">Update your photo and personal details</h4>
      </div>
            </div>
        </div>
        <div className="inputypetest">
        <div className="row">
            <div className="col-lg-5 col-md-12 col-12">
                <div className="editprofileinputlist">
                    <h2>Display Name</h2>
                    <div className="editprofiledisplay">
                        <img src={User} className="mr-2" />
                        <input type="text"id="name" placeholder="Enter Display Name" value = {name}onChange={onChange} autoComplete="off" />
                    </div>
                </div>
                {validateError.names && (
                      <span className="text-danger">{validateError.names}</span>
                    )}
            </div>
            <div className="col-lg-2 col-md-12 col-12">

            </div>

            <div className="col-lg-5 col-md-12 col-12">
                <div className="editprofileinputlist">
                    <h2>Email</h2>
                    <div className="editprofiledisplay">
                        <img src={Mail} className="mr-2" />
                        <input type="text" id="email" placeholder="Enter Email Address" value={email}
                        onChange={onChange} autoComplete="off" />
                    </div>
                </div>
                {validateError.email && (
                      <span className="text-danger">{validateError.email}</span>
                    )}
            </div>

           
        </div>
        </div>
      
        <div className="bidnowbtn mt-5">




                  {disablebtn === 0 && (
                               <button className="connectwallet mr-3" onClick={(e)=>handleFormSubmit(e)}>Update Profile</button>

                  )}
                  {disablebtn === 1 && (
                                <button className="connectwallet mr-3"  disabled={true}>Form Error</button>

                  )}
                  {disablebtn === 2 && (
                             <button className="connectwallet mr-3" onClick={(e)=>handleFormSubmit(e)}>try Again</button>

                  )}
                  {disablebtn === 2 && (
                              <button className="connectwallet mr-3"  disabled={true}>try again</button>

                  )}

            <button className="connectwallet"  onClick={()=>history.push("/")}>Cancel</button>

        </div>
        </div>
      </div>

       
        <Footer />
        </div>
        <div class="modal fade" id="editprofile" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Update Cover</h5>
      
      </div>
      <div class="modal-body">
      <p>Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution and supported format PNG,GIF,WEPG,JPG</p>
      </div>
      <div class="modal-footer">
      
        <div className="chooseimgaebtn">
            <input type="file" id="cover" onChange={(e)=>handleFile1(e)}/>
        <button type="button" class="buybtn">Choose Image</button>
        </div>
      </div>
    </div>
  </div>
</div>




        </div>
    )
}
