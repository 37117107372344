import React,{useEffect} from "react";
import Home from "./views/home.js";
import Explore from "./views/explore.js";
import Myitems from "./views/myitems.js";
import Activity from "./views/activity.js";
import Info from "./views/info.js";
import Editprofile from "./views/editprofile";
import Search from "./views/search";
import Faq from "./views/faq";
import About from "./views/about";
import unlock from "./views/unlockdatas";
import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import './index.css';
import Privacypolicy from "./views/privacyPolicy";
import Terms from "./views/termsOfService"
import Disclaimer from "./views/disclaimer"
import Resubscription from "./views/Resubscription.js";
import ResubscriptionDetails from "./views/ResubscriptionDetails.js";
function App() {
  useEffect(() => {
    window.scrollTo(0,0); 
},[]);
  return (
<>

<BrowserRouter basename='/'>
  <Switch>
<Route exact path="/explore/:Param_category" component={Explore} />
<Route exact path='/my-account' component={Myitems} />
<Route exact path="/user/:paramAddress" component={Myitems} />
<Route exact path='/about' component={About} />
<Route exact path='/activity' component={Activity} />
<Route exact path="/resubscribtion/:owner/:tokenid/:subid" component={Resubscription} />
<Route exact path="/resubscribtiondetails" component={ResubscriptionDetails} />
<Route exact path="/info/:nftname/:owneraddress/:collectionaddress/:tokenidval" component={Info} />
<Route exact path='/editprofile' component={Editprofile} />
<Route path="/privacy-policy" component={Privacypolicy} />
<Route path="/terms-of-service" component={Terms} />
<Route path="/disclaimer" component={Disclaimer} />
<Route exact path='/unlock' component={unlock} />
<Route exact path='/search' component={Search} />
<Route exact path='/faq' component={Faq} />
<Route path="/" component={Home} />
<Route exact path="/*" component={Home}>
                        <Redirect to="/" />
                    </Route>
</Switch>
</BrowserRouter>
</>
  );
}

export default App;
