import DETH_ABI from '../ABI/DETH_ABI.json';
import '@metamask/legacy-web3'
import axios from 'axios';
let fee1 ='';
var chainIds=1;
const multipleType = 1155;
const Type = 1155;

// let currencySymbol = 'AVAX';
// let currencySymbol1 = 'avax';
// let currencySymbolCheck = 'AVAX';
// let cryptoId='avalanche-2'
// let tokenSymbol = 'CAKE';
// let kybcurreny = 'Avax';
let currencySymbol = 'BNB';
let currencySymbol1 = 'bnb';
let currencySymbolCheck = 'BNB';
let cryptoId='bnb'
let tokenSymbol = 'CAKE';
let kybcurreny = 'BNB';
let maxLength=13;
var WenlamboConvertVal=0
let PurchaseTransferType = 'currency';
// var tokenAddr= { CAKE: "0xeb4ffed6b324b5fdc4d72ad3da75bf1fb2bb004a".toLowerCase() }///////////avax
var tokenAddr= { CAKE: "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase() }///////////Bnb

var dead_Address ="0x000000000000000000000000000"
var tokenAddress = "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase()
var tokenABI= { CAKE: DETH_ABI }

let BNBPROVIDER="";
let adminaddr="";
let Front_URL = '';
let Back_URL = '';
let Users_URL = '';
let v1Url = '';
let limit = 100;
var My_item_Limit=3000;
// let Lod=Loder;
let limitMax = 100;
let decimalvalues = 1000000000000000000;
let toFixed = 6;
var tokenFee=0;
let IPFS_IMG = "https://unblokdata.infura-ipfs.io/ipfs"
var nameFormat = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
var TradeContract   ="";
var singleContract ="";
var multipleContract = "";
var chainId ="";
var CHAIN_ID=43113
var networkVersion ="";
var shareTag=[]
var adminaddress="0x025c1667471685c323808647299e5dbf9d6adcc9"
var toasterOption = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    limit:1
}
var encodekey = "UNBLOK@#%987"
var providercon=null;
var buyerfee=0;
var sellerfee=0; 
var KybpriceUsd=1
var subscribeAmount=5

const projectId = '6d423cb5d01f32feb5d15e2e8c19252c'

let EnvName = 'demo';
let fee=fee1;
var currAdrress='';
if (EnvName === "production") {
   }
else if (EnvName === "demo") {   
    var learnmore = process.env.REACT_APP_Learnmore_demo
    var exchangeAddress     = "";
    // Front_URL               =   'https://nftdemo3.maticz.com';
    // Users_URL               =   'https://nftdemo3.maticz.com:9365/user';
    // Back_URL                =   'https://nftdemo3.maticz.com/api';
    // v1Url                   =   'https://nftdemo3.maticz.com/api/v1';
    https://unblokdata.com/
    // Front_URL               =   'https://nftdemo3.maticz.com';
    // Users_URL               =   'https://nftdemo3.maticz.com:9365/user';
    Front_URL               =   'https://unblokdata.com/';
    Users_URL               =   'https://unblokdata.com/user';
    Back_URL                =   'https://api.unblokdata.com';
    v1Url                   =   'https://api.unblokdata.com/v1';

    /////////////////////////////AVAX/////////////////////////
    // multipleContract        =   "0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2".toLowerCase()
    // TradeContract           =   "0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2".toLowerCase()
    // adminaddr               =   "0x025c1667471685c323808647299e5DbF9d6AdcC9"
    // BNBPROVIDER             =   "https://api.avax-test.network/ext/bc/C/rpc";
    // networkVersion          =   '43113';
    // chainId                 =   '0x43113';
   /////////////////////////////////////////////////////////////


    /////////////////binance/////////////////////////////////////////
    networkVersion          =   '97';
    chainId                 =   '0x97';
    var chainid             =    97
    adminaddr               =   "0x025c1667471685c323808647299e5DbF9d6AdcC9"
    multipleContract        =   "0xdD92b37EfEd185868Eeea7885a502C098EE4719d".toLowerCase()
    TradeContract           =   "0xdD92b37EfEd185868Eeea7885a502C098EE4719d".toLowerCase()
    BNBPROVIDER             =   "https://data-seed-prebsc-1-s3.binance.org:8545/";
    ///////////////////////////////////////////////////////////////

}
else if(EnvName === "testing")
{
    var exchangeAddress     =   "";

    Front_URL               =   'https://nftdemo4.maticz.com';
    Users_URL               =   'https://nftdemo4.maticz.com:9366/user';
    Back_URL                =   'https://nftdemo4.maticz.com/api';
    v1Url                   =   'https://nftdemo4.maticz.com/api/v1';
    adminaddr               =   "0x025c1667471685c323808647299e5DbF9d6AdcC9"
    BNBPROVIDER             =   "https://data-seed-prebsc-1-s1.binance.org:8545/";
    networkVersion          =   '97';
    chainId                 =   '0x97';

multipleContract            =  "0x43cE3E9189a993521761C916615D53bc84a89Ed7".toLowerCase()
TradeContract               = "0x43cE3E9189a993521761C916615D53bc84a89Ed7".toLowerCase()

}
else {
    var learnmore = process.env.REACT_APP_Learnmore_local
    var exchangeAddress     = "";
    var chainid = 97
    Front_URL               =   'http://localhost:3000';
    Users_URL               =   'http://localhost:3000/user';
    Back_URL                =   'http://localhost:2002';
    v1Url                   =   'http://localhost:2002/v1';
    adminaddr               =   "0x025c1667471685c323808647299e5DbF9d6AdcC9"
    /////////////////avax/////////////////////////////////////////    
    // networkVersion          =   '43113';
    // chainId                 =   '0x43113';
    // multipleContract        =   "0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2".toLowerCase()
    // TradeContract           =   "0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2".toLowerCase()
    // BNBPROVIDER             =   "https://api.avax-test.network/ext/bc/C/rpc";
    ///////////////////////////////////////////////////////////////
    /////////////////binance/////////////////////////////////////////
    networkVersion          =   '97';
    chainId                 =   '0x97';
    multipleContract        =   "0xdD92b37EfEd185868Eeea7885a502C098EE4719d".toLowerCase()
    TradeContract           =   "0xdD92b37EfEd185868Eeea7885a502C098EE4719d".toLowerCase()
    BNBPROVIDER             =   "https://data-seed-prebsc-1-s3.binance.org:8545/";
    ///////////////////////////////////////////////////////////////
}

async function tag(){
    var id="0000"
    await axios.get(v1Url+'/admin/panel/getnfttag/'+id)
    .then((data)=>{
        if(data&&data.data&&data.data.userValue){
            (data.data.userValue).map((item)=>{
                (shareTag).push(item.nfttag)
                return item   
        })}
    })
}

tag();
       
var My_item_Page        =   1;
let key = {
    Front_URL           :   Front_URL,
    Back_URL            :   Back_URL,
    v1Url               :   v1Url,
    vUrl                :   v1Url,
    fee                 :   fee,
    decimalvalues       :   decimalvalues,
    toFixed             :   toFixed,
    networkVersion      :   networkVersion,
    currencySymbol      :   currencySymbol,
    currencySymbol1      :   currencySymbol1,
    tokenSymbol         :   tokenSymbol,
    toasterOption       :   toasterOption,
    limit               :   limit,
    limitMax            :   limitMax,
    exchangeAddress     :   exchangeAddress,
    multipleContract    :   multipleContract,
    tokenAddr           :   tokenAddr,
    multipleType        :   multipleType,
    IPFS_IMG            :   IPFS_IMG,
    BNBPROVIDER         :   BNBPROVIDER,
    tokenABI            :   tokenABI,
    PurchaseTransferType:   PurchaseTransferType,
    maxLength           :   maxLength,
    Users_URL           :   Users_URL,
    chainId             :   chainId,
    WenlamboConvertVal  :   WenlamboConvertVal,
    currAdrress         :    currAdrress,
    tokenFee            :   tokenFee,
    sellerfee           :   sellerfee,
    buyerfee            :   buyerfee,
    shareTag            :   shareTag,
    providercon         :   providercon,
    nameFormat          :   nameFormat,
    chainIds            :   chainIds,
    trade               :   TradeContract,
    My_item_Limit       :   My_item_Limit,
    My_item_Page        :   My_item_Page,
    adminaddress        :   adminaddress,
    learnmore:learnmore,
    currencySymbolCheck:currencySymbolCheck,
    chainid:chainid,
    adminaddr:adminaddr,
    projectId:projectId,
    KybpriceUsd:KybpriceUsd,
    tokenAddress:tokenAddress,
    kybcurreny:kybcurreny,
    cryptoId:cryptoId,
    subscribeAmount:subscribeAmount,
    encodekey: encodekey,
    Type:Type
};

export default key;

