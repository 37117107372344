import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Header from "../components/Header/Head";
import Profile from "../assets/images/Artworks/fdisc.webp";
import Footer from "../components/Footer/Footer.js";
import ReactHtmlParser from "react-html-parser";
import { faqlists } from "../actions/v1/report";
import "./disclaimer.css";
import disclaimBannerImg from "../assets/Pages/Disclaimer/headerRightImage.svg";
import termsAccordianStars from "../assets/Pages/Terms-and-service/accordianStar.svg";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Disclaimer(props) {
  const [eventkey, setEventkey] = useState(0);
  const [faqlist, setfalist] = useState([]);
  const [TopContent, setTopContent] = useState([]);

  useEffect(() => {
    faqlistfunc();
  }, [eventkey]);
  const faqlistfunc = async () => {
    var fql = await faqlists({ dbname: "disclaimer" });

    if (fql && fql.data && fql.data.soci) {
      setfalist(
        fql?.data?.soci?.length > 0 &&
          fql?.data?.soci?.map((ite) => (ite?.TopContent ? false : ite))
      )?.filter((ite) => ite && ite);

      setTopContent(
        fql?.data?.soci?.length > 0 &&
          fql?.data?.soci
            ?.map((ite) => (ite?.TopContent ? ite.TopContent : false))
            ?.filter((ite) => ite && ite)[0]
      );
    }
  };

  return (
    <div className="inner_header otherpage disclaimerPge">
      <Header />
      <ScrollToTopOnMount />
      <div className="container-fluid p-0 disclaimBanner">
        <div className="termsofservice">
          <div className="termstextBox">
            <h1>Disclaimer</h1>
          </div>
          <div className="termsimageBox">
            <img src={disclaimBannerImg} alt="disclaimBannerimage" />
          </div>
        </div>
        <div className="topBannerDisclaimer">
          <h4>CYBER RISK DISCLAIMER</h4>
          <p>
            {" "}
            This Disclaimer is a legal agreement between you (User) and UNBLOK
            LLC (Company) regarding your use of the UNBLOK platform. If you
            don't agree with these terms, do not use our services. Your use of
            the platform means you accept and are bound by this Disclaimer.
          </p>
          <h4>BACKGROUND</h4>{" "}
          <p>
            As technology becomes more integral to our lives, cyber risks like
            fraud, data loss, and breaches are on the rise. Cyber fraud involves
            using deceptive tactics to steal sensitive information. Data loss
            happens when data is accidentally or deliberately destroyed. Data
            breaches involve unauthorized access to confidential information.
            These risks can have serious consequences for individuals,
            businesses, and governments. Cyber threats are always evolving, so
            staying informed about the latest risks and safeguarding your data
            is crucial.<br></br>
            <br></br> Cybercriminals are getting more sophisticated and target
            individuals and businesses of all sizes. They're often a step ahead
            of security measures. Keeping up with the latest threats and
            protecting your data is essential. Cybercriminals can use stolen
            information for fraud or sell it on the black market. If you're a
            victim of cybercrime, act promptly and contact the authorities.
            <br></br>
            <br></br> To protect yourself, practice online security vigilance.
            Use strong, unique passwords for each account, avoid clicking on
            unfamiliar links or attachments, and install reliable antivirus
            software. These precautions help guard against cyber threats.
          </p>
        </div>
      </div>
      <div className="disclaimBanner_bgcolor">
        <div className="container-fluid custom-container disclaimer-sevice-content">
          <div className="termsofservice">
            <div className="disc_accord pt-5">
              {ReactHtmlParser(TopContent)}
            </div>

            <Accordion className="disc_removelast">
              <div className="terms-accordian-stars" style={{justifyContent:"end"}}>
                <img src={termsAccordianStars} alt="termsAccordianStars" />
              </div>
              {faqlist.length > 0 &&
                faqlist
                  .filter((ite) => ite && ite)
                  .map((data, key) => {
                    return (
                      <Accordion.Item
                        eventKey={JSON.stringify(key)}
                        className="tf-toggle disc_accord"
                      >
                        <Accordion.Header className="tf-toggle-title">
                          <h6>{data.question}</h6>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="robotoReg faq_anstxtclr disc_accord"
                            dangerouslySetInnerHTML={{ __html: data.answer }}
                          ></div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
            </Accordion>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}