import React, {
  forwardRef,
  useEffect,
  useState
} from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import{subdetail} from "../actions/v1/token"
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import config from '../lib/config'
import Web3 from 'web3';
import trade from "../ABI/Trade.json"
import { getReceipt } from '../actions/v1/getReceiptFunc';
import { useHistory, useLocation } from "react-router-dom";

toast.configure();
export const Subscribe = forwardRef((props, ref) => {
  var 
  {
    item,
    owner
  }=props
  const Wallet_Details = useSelector(state => state.wallet_connect_context);
  const history = useHistory();

  const [FormSubmitLoading, Set_FormSubmitLoading] = useState('init');
  const[SubsPrice,setSubsPrice]=useState(0)

  useEffect(()=>
{
  init()
},[item.tokenCounts])

const init = async()=>
{
 
try{


  var web3 = new Web3(config.BNBPROVIDER)


  var CoursetroContract = new web3.eth.Contract(trade,config.trade);



var subsPrice = await CoursetroContract
.methods
._subscribeNFT(item.tokenCounts)
.call();



setSubsPrice(subsPrice.subPrice)

    }
    catch(err)

    {
      
    }


  
}


const subscribeproceed =async()=>
  {
try{  
    var receipt=''
var handle =''
    var web3 = new Web3(Wallet_Details.providerss)

    var CoursetroContract = new web3.eth.Contract(
      trade,
      config.trade
  );


    Set_FormSubmitLoading('processing');
   
    await CoursetroContract.methods.nftSubscription( item.tokenCounts, )
        .send({ from: Wallet_Details.UserAccountAddr,value:SubsPrice })
        .on('transactionHash', async (transactionHash) => {
           handle = setInterval(async () => {
                receipt = await getReceipt(web3, transactionHash)
                
                clr1();
            }, 8000)
        })

        async function clr1() {
          if (receipt !== null) {
              clearInterval(handle);
var date =new Date()
var subenddate=new Date(date.setDate(date.getDate() + 1))

if(owner)
{


var data={
owner:owner,
tokenCounts:item.tokenCounts,
substatus:true,
SubscribeStart:new Date(),
SubscribeEnd:subenddate,
contractaddress:item.contractAddress,
tokenName:item.tokenName
}
  


const Subsave = await subdetail(data)


if(Subsave?.data?.datas)
{

toast.success("Subscription Successful")
history.push("/")

   window.$('#subscribe').modal('hide');
}
else{
}
  }
      }
    }
    }
    catch(err)

    {
      
    }


  }

return(
    
    <>
<div className="modal fade" id="subscribe" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">Proceed to Pay</h5>
      </div>
      <div className="modal-footer">
        <button onClick={()=>subscribeproceed()} className="buybtn mt-3" disabled={FormSubmitLoading==="processing"&&"true"}>{FormSubmitLoading==="init"&&"Start"}
        {FormSubmitLoading==="processing"&&"processing"}
        {FormSubmitLoading==="true"&&"true"}</button>
        <button className="bidbtn" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

    </>
  )
})
