import Head from "../components/Header/Head";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useState, } from "react";
import moment from "moment";

function ResubscriptionDetails(props) {
  const location = useLocation();
  const [subscribedet, setsubscribedet] = useState(location.state);

  return (
    <>
      <div className="activity otherpage">
        <Head />
        <div className="activitysec">
          <div className="container-fluid custom-container">
            <div className="table-responsive mt-5">
              <table class="table table-hover">
                <thead>
                  <tr className="tabelrowe" id="tabelrowes">
                    <th scope="col">Create Date</th>
                    <th scope="col">NFT Name</th>
                    <th scope="col">Remaining Amount</th>
                    <th scope="col">Remaining Days</th>
                    <th scope="col">Total subscribed Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {subscribedet.length > 0 &&
                    subscribedet.map((ite) => (
                      <tr key={ite.id}>
                        <td>
                          <div className="dateprofile resub_dateprofile">
                            <p>
                              <Link to="/your-link-here">
                                {moment(ite?.createdAt).format().split("T")[0]}
                              </Link>
                            </p>
                          </div>
                        </td>
                        <td className="editpeors">{ite.tokenName}</td>
                        <td>{ite.RemainingAmount}</td>
                        <td className="editpeors">
                          <Link to="/your-link-here">{ite?.RemainingDays}</Link>
                        </td>
                        <td>{ite.TotsubscribedAmount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResubscriptionDetails;
