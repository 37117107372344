import React from "react";
import "./SalesItem.css";
import devider from "../../assets/images/Markeetplace/itemDevider.svg";
const SalesItem = ({sales}) => {
  return (
    <div className="salesItemBody">
      <div className="detail-image">
        <img src={sales.Image} alt="" />
      </div>
      <div className="detail_item">
        <h5>{sales.title}</h5>
        <img src={devider} alt="" />
        <h5 style={{ color: "#a3a0a0" }}>{sales.price}</h5>
      </div>
    </div>
  );
};

export default SalesItem;
