import { useEffect, useState, useRef } from "react";
import { LikeRef } from "../components/LikeRef";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Profile from "../assets/images/profile_1.jpg";
import Tokencard from "../components/seperate/tokencard.js";
import Load from "../assets/images/load_more.gif";
import { getSearchList } from "../actions/v1/user";
import isEmpty from "../lib/isEmpty";
import config from "../lib/config";

export default function Search(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [searchItem, setSearchItem] = useState([]);
  const [UsersearchItem, setUserSearchItem] = useState([]);
  const [item, Set_item] = useState({});
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = useState("");
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});

  var LikeForwardRef = useRef();

  useEffect(() => {
    searchCall();
  }, []);

  const searchCall = async () => {
    if (isEmpty(props.location.search)) return false;
    const search = props.location.search;
    const params = new URLSearchParams(search);
    const keyword = params.get("search");

    let postData = {
      keyword: keyword,
      limit: parseInt(config.limitMax),
    };

    var data = await getSearchList(postData);

    if (
      data &&
      data.searchlist &&
      data.searchlist.items &&
      data.searchlist.items.list
    ) {
      setSearchItem(data.searchlist.items.list);
    }
    if (data && data.searchlist && data.searchlist.users) {
      setUserSearchItem(data.searchlist.users);
    }
  };

  return (
    <>
      <div className="explorepage otherpage">
        <LikeRef
          ref={LikeForwardRef}
          setLikedTokenList={setLikedTokenList}
          MyItemAccountAddr={MyItemAccountAddr}
        />
        <Header />
        <div className="explorepagesec search mt-5">
          <div className="container-fluid custom-container">
            <div className="tabbable-panel">
              <div className="tabbable-line">
                <ul className="nav nav-tabs ">
                  <li className="active">
                    <a href="#allcategories" data-toggle="tab">
                      Items
                    </a>
                  </li>
                  <li>
                    <a href="#buynows" data-toggle="tab">
                      Users
                    </a>
                  </li>
                </ul>
              </div>
              <div class="tab-content mt-5">
                <div class="tab-pane active" id="allcategories">
                  {searchItem.length === 0 ? (
                    <div className="text-center py-5 ">
                      <p className="not_found_text">No Data Found</p>
                      <div className="mt-3"></div>
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row mt-5 pb-5">
                        {searchItem.map((item, index) => {
                          return isEmpty(item.tokenowners_current) !== true ? (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6 card_client">
                              <Tokencard
                                item={item}
                                Set_item={Set_item}
                                LikedTokenList={LikedTokenList}
                                hitLike={
                                  LikeForwardRef.current &&
                                  LikeForwardRef.current.hitLike
                                }
                                Set_Bids={Set_Bids}
                                Bids={item.myBid}
                                Set_BuyOwnerDetailFirst={
                                  Set_BuyOwnerDetailFirst
                                }
                                Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                                Set_MyTokenBalance={Set_MyTokenBalance}
                                Set_MyTokenDetail={Set_MyTokenDetail}
                                Set_AllowedQuantity={Set_AllowedQuantity}
                                Set_YouWillPay={Set_YouWillPay}
                                Set_YouWillPayFee={Set_YouWillPayFee}
                                Set_YouWillGet={Set_YouWillGet}
                              />
                            </div>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div class="tab-pane" id="buynows">
                  {UsersearchItem.length === 0 ? (
                    <div className="text-center py-5 ">
                      <p className="not_found_text"></p>

                      <p className="not_found_text">No Users Found</p>

                      <div className="mt-3"></div>
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row mt-5 pb-5">
                        {UsersearchItem.map((User) => {
                          return (
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6 col-6 card_client">
                              <div className="usericons">
                                {User?.image != "" ? (
                                  <img
                                    src={`${config.Back_URL}/images/${User.curraddress}/${User.image}`}
                                    className="mr-3"
                                  />
                                ) : (
                                  <img src={Profile} className="mr-3" />
                                )}
                                <div>
                                  {User.name && <h3>{User.name}</h3>}
                                  {User.curraddress && (
                                    <h4>
                                      {User.curraddress
                                        .slice(0, 15)
                                        .concat("...")}
                                    </h4>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {UsersearchItem?.length > 0 &&
                    UsersearchItem?.length > config.limitMax && (
                      <div className="loadmorebtn">
                        <img src={Load} />
                        <h2>Load More</h2>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
