/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { sociallinksfunction } from "../../actions/v1/report";
import config from "../../lib/config";
import { useSelector } from "react-redux";
import unblokLogo from "../../assets/images/Header/updated-site-logo.svg";
import twiter from "../../assets/images/Footer/twitter.svg";
import instagram from "../../assets/images/Footer/instagram.svg";
import facebook from "../../assets/images/Footer/facebook.svg";

export default function Footer() {
  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const [sociallinks, setsociallinks] = useState({});
  useEffect(() => {
    sociallinksfunct();
  }, [
    Wallet_Details.UserAccountAddr,
    localStorage.walletConnectType,
    config.providercon,
  ]);

  const sociallinksfunct = async () => {
    var soci = await sociallinksfunction();
    if (soci !== undefined && soci?.data?.soci !== undefined) {
      setsociallinks(soci.data.soci);
    } else {
      setsociallinks({});
    }
  };

  const explore = () => {
    document.body.scrollTop = 0;
  };

  return (
    <>
      <footer className="footerhead scroll-to-top">
        <div className="container custom-container footer_bgcolor">
          <div className="row pb-3">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
              <div className="footer-logoWrap">
                <Link to="/" className="footer__logo">
                  <img
                    alt="Footer-Logo"
                    className="footer_top_logo"
                    src={unblokLogo}
                  />
                </Link>
              </div>
              <p className="robotoReg footer_xpln">
                Explore the limitless possibilities of our Data NFTs to power
                your investment decisions.
              </p>
              <div className="footersociallinks">
                <a
                  href="https://www.facebook.com/unblokapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} style={{ width: "28px" }} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/unblokapp/"
                  target="_blank"
                  alt=""
                  rel="noreferrer"
                >
                  <img src={instagram} style={{ width: "28px" }} alt="" />
                </a>
                <a
                  href="https://twitter.com/unblokapp"
                  target="_blank"
                  alt=""
                  rel="noreferrer"
                >
                  <img src={twiter} style={{ width: "28px" }} alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
              <div className="row footer_blocks">
                <h2 className="robotoBold">Data Collections</h2>
                <div className="footer-columns-wrapper">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <div className="scroll-to-top"></div>
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Shopping"
                            onClick={explore}
                          >
                            Shopping
                          </Link>
                        </li>

                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Social Media"
                            onClick={explore}
                          >
                            Social Media{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Travel"
                            onClick={explore}
                          >
                            Travel
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Browser"
                            onClick={explore}
                          >
                            Browser
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/News"
                            onClick={explore}
                          >
                            News
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Search"
                            onClick={explore}
                          >
                            Search
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                    <div className="quicklinks">
                      <ul>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Entertainment"
                            onClick={explore}
                          >
                            Entertainment
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="homelinklink"
                            to="/explore/Transportation"
                            onClick={explore}
                          >
                            Transportation
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
            <div className="copyrightWrap" id="desktopcopyrightWrap">
              <p className="copyrights">
                Copyright ©2024. All rights reserved.
              </p>
              <div className="copyrightsde">
                <ul>
                  <li>
                    <a href="/privacy-policy">
                      Privacy Policy
                    </a>{" "}
                    |{" "}
                    <a href="/terms-of-service">
                      Terms of Service
                    </a>{" "}
                    |{" "}
                    <a href="/disclaimer">
                      Disclaimer{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
      </footer>
    </>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
