import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header/Head.js";
import Footer from "../components/Footer/Footer.js";
import Load from "../assets/images/load_more.gif";
import Profile from "../assets/images/profile.jpg";
import { useSelector } from "react-redux";
import { ActivityCall } from "../actions/v1/report";
import { Link } from "react-router-dom";
import config from "../lib/config";
import { Button } from "@material-ui/core";
import isEmpty from "../lib/isEmpty";
import create from "../assets/images/create.png";
import cart from "../assets/images/black/Shopping.svg";
import Like from "../assets/images/black/Like.svg";
import Unlike from "../assets/images/black/Dislike.svg";
import Follow from "../assets/images/follow.png";
import Unfollow from "../assets/images/unfollow.png";
import approved from "../assets/images/approved.png";
import Bid from "../assets/images/bid.png";
import accept from "../assets/images/accept.png";
import cancel from "../assets/images/cancel.png";
import price from "../assets/images/price.png";
import transfer from "../assets/images/transfer.png";
import burn from "../assets/images/burn.png";
import putonsale from "../assets/images/putonsale.png";
import editbid from "../assets/images/editbid.png";
import Loader from "./Loader.js";
import explore_devider from "../assets/images/Markeetplace/explore_devider.svg";
import activity_devider from "../assets/images/Markeetplace/activity_devider.svg";
import explore_star from "../assets/images/Markeetplace/star.svg";
import explore_round from "../assets/images/Markeetplace/round.svg";

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default function Activity() {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);

  const [page, setpage] = useState(1);
  const [loading, setloading] = useState(false);
  const prevpage = usePrevious(page);
  const [Activity_Item, Set_Activity_Item] = useState([]);
  const [isload, setisload] = useState(true);

  useEffect(() => {
    ActivityTab("All");
  }, [Wallet_Details.UserAccountAddr]);

  useEffect(() => {
    ActivityTab("All");
  }, [page]);

  const ActivityTab = async (data) => {
    if (Wallet_Details?.UserAccountAddr !== "") {
      setloading(true);
      var reqdata = {
        tabName: data,
        currAddr: Wallet_Details.UserAccountAddr,
        limit: 10,
        page: page,
      };

      try {
        var activitys = await ActivityCall(reqdata);

        setloading(false);
        if (
          activitys &&
          activitys.data &&
          activitys.data.list &&
          activitys.data.list.length > 0
        ) {
          if (prevpage !== page) {
            var arr = Activity_Item;
            arr = arr.concat(activitys.data.list);

            Set_Activity_Item(arr);
          } else if (prevpage === 1 && page === 1) {
            Set_Activity_Item(activitys.data.list);
          }
        } else {
          setisload(false);
        }
      } catch (err) {}
    }
  };

  var CreatedTime = (Actdate) => {
    var today = new Date();
    var Christmas = new Date(Actdate);
    var diffMs = today - Christmas; // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    return diffHrs === 0 && diffDays === 0
      ? `${diffMins} minutes ago`
      : diffHrs === 1
      ? `${diffHrs} hour ago`
      : diffDays === 0
      ? `${diffHrs} hours ago`
      : diffDays === 1
      ? `${diffDays} day ago`
      : `${diffDays} days ago`;
  };

  var imgArr = [
    { name: "mint", image: <img src={create} /> },
    { name: "purchase", image: <img src={cart} /> },
    { name: "bid", image: <img src={Bid} /> },
    { name: "accept", image: <img src={accept} /> },
    { name: "editbid", image: <img src={editbid} /> },
    { name: "like", image: <img src={Like} /> },
    { name: "unlike", image: <img src={Unlike} /> },
    { name: "follow", image: <img src={Follow} /> },
    { name: "unfollow", image: <img src={Unfollow} /> },
    { name: "burn", image: <img src={burn} /> },
    { name: "putonsale", image: <img src={putonsale} /> },
    { name: "cancel price", image: <img src={cancel} /> },
    { name: "Price Changed  by", image: <img src={price} /> },
    { name: "transfer", image: <img src={transfer} /> },
    { name: "Approved", image: <img src={approved} /> },
    { name: "subscribe", image: <img src={approved} /> },
  ];

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="activity otherpage">
        <Header />
        <div className="activitysec">
          <div className="container custom-container">
            <div className="row pt-lg-5 pt-md-4 position-relative image-box">
              <div>
                <img
                  id="purpleImge1"
                  className="img1 position-absolute"
                  src={explore_star}
                  alt="explore_star"
                />
                <img
                  id="purpleImge2"
                  className="img2 position-absolute"
                  src={explore_star}
                  alt="explore_star"
                />
                <img
                  className="img3 position-absolute"
                  src={explore_round}
                  alt="explore_star"
                />
              </div>
              <div className="col-md-12">
                <div className="explorepage-title">
                  <div className="explore-title-span">
                    <span style={{ color: "gray" }}>Home</span>
                    <span>| Activity</span>
                  </div>
                  <h2>ACTIVITY</h2>
                  <div
                    className="explore-digital-assest"
                    style={{ marginTop: "0px" }}
                  >
                    <img
                      src={explore_devider}
                      width="90%"
                      alt="activity_devider"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="activity-tabs">
              <h4>Activity</h4>
              <img src={activity_devider} width="100%" alt="activity_devider" />
              <ul className="nav nav-tabs ">
                <li className="active">
                  <a href="#info" data-toggle="tab">
                    Info
                  </a>
                </li>
                <li>
                  <a href="#owner" data-toggle="tab">
                    Activity
                  </a>
                </li>
                <li>
                  <a href="#bid" data-toggle="tab">
                    Bid
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="table-responsive mt-5 "
              style={{
                marginBottom: "6rem",
              }}
            >
              <table class="table table-hover">
                <thead>
                  <tr className="tabelrowe" id="tabelrowes">
                    <th scope="col" className="salecals">
                      Transaction
                    </th>
                    <th scope="col">Items</th>
                    <th scope="col">Price</th>
                    <th scope="col">QTY</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col" className="salecalss">
                      Time
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {Activity_Item.map((item) => {
                    var iconArr = imgArr.filter(
                      (dataaa) => item.action === dataaa.name
                    );
                    if (iconArr.length > 0) var icon = iconArr[0].image;

                    return (
                      <tr>
                        <td className="editpeors">{icon}</td>
                        <td>
                          <div className="dateprofile">
                            {
                              <Link
                                to={`/info/${String(
                                  item.tokens_current.tokenName
                                )}/${item.tokens_current.tokenOwner}/${
                                  item.tokens_current.contractAddress
                                }/${item.tokenCounts}`}
                              >
                                <img
                                  src={
                                    item.tokens_current.ipfsimage &&
                                    item.tokens_current.ipfsimage !== undefined
                                      ? `${config.IPFS_IMG}/${item.tokens_current.ipfsimage}`
                                      : item.tokens_current.image
                                      ? `${config.Back_URL}/compressedImage/${item.tokens_current.tokenCreator}/${item.tokens_current.image}`
                                      : Profile
                                  }
                                  alt=""
                                />
                              </Link>
                            }

                            <p>
                              {" "}
                              <Link to={`/user/${item?.toField?.curraddress}`}>
                                {item.tokens_current.tokenName}{" "}
                              </Link>
                            </p>
                          </div>
                        </td>
                        <td className="editpeors">
                          {item.amount ? item.amount + " " : 0}
                          {item.tokens_current.CoinName != undefined
                            ? item.tokens_current.CoinName
                            : ""}
                        </td>
                        <td>{item?.balance}</td>

                        <td className="editpeors">
                          {" "}
                          <Link to={`/user/${item?.from}`}>
                            {item?.from?.slice(0, 14)?.concat("...")}
                          </Link>
                        </td>
                        <td className="">
                          <Link to={`/user/${item?.to}`}>
                            {!isEmpty(item.toField)
                              ? item?.to?.slice(0, 14).concat("...")
                              : "..."}
                          </Link>
                        </td>
                        <td className="timing editpeors">
                          {CreatedTime(item?.created)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* {isload && (
                <div className="loadmorebtn mt-4">
                  {loading ? (
                    <img src={Load} />
                  ) : (
                    <Button
                      id="onmore"
                      onClick={() => setpage(page + 1)}
                      className="expore connectwallet"
                    >
                      Load More
                    </Button>
                  )}
                </div>
              )} */}
            </div>
          </div>
        </div>

        <Footer />
      </div>

      <div
        class="modal fade"
        id="editprofile"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Update Cover
              </h5>
            </div>
            <div class="modal-body">
              <p>
                Upload new cover for your profile page. We recommended to upload
                images in 1140×260 resolution and supported format
                PNG,GIF,WEPG,JPG
              </p>
            </div>
            <div class="modal-footer">
              <div className="chooseimgaebtn">
                <input type="file" />
                <button type="button" class="connectwallet">
                  Choose Image
                </button>
              </div>
              <button type="button" class="bidbtn" data-bs-dismiss="modal">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="shareicons"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Share Link
              </h5>
            </div>
            <div class="modal-body">
              <div className="shareiconslinkss">
                <i class="fab fa-twitter"></i>
                <i class="fab fa-instagram-square"></i>
                <i class="fab fa-facebook-f"></i>
              </div>
              <div className="copylinks">
                <input type="text" placeholder="Example.com/Share Link" />
                <button type="button" class="connectwallet">
                  Share
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="connectwallet"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
