import React from "react";
import Load from "../assets/images/load_more.gif";
export default function Loader() {
  return (
    <div>
      <div id="loader_div_modal" className="popup_loader">
        <div className="pls_wait">
          {/* <img
            src={Load}
            className="logo_load_modal spin_round"
            id="logo_spin_modal"
            width="50"
            alt=""
          /> */}
          <div class="custom-loader"></div>
          <p style={{ marginTop: 70 }}>Loading .....</p>
        </div>
      </div>
    </div>
  );
}
