import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react";
import { useHistory } from "react-router-dom";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import Web3 from "web3";
import {
  TokenPriceChange_update_Action,
  checkOtherPlatformDetais1155,
} from "../../actions/v1/token";
import { toast } from "react-toastify";
import config from "../../lib/config";
import isEmpty from "../../lib/isEmpty";
import MULTIPLE from "../../ABI/MULTIPLE.json";
import { getReceipt } from "../../actions/v1/getReceiptFunc";
import trade from "../../ABI/Trade.json";

toast.configure();
let toasterOption = config.toasterOption;
export const Putonsale = forwardRef((props, ref) => {
  var { item } = props;
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const history = useHistory();
  const [suboutOfDateQuantity, setsuboutOfDateQuantity] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [PutOnSale, setPutOnSale] = useState(false);
  const [PutOnSaleType, setPutOnSaleType] = useState("");
  const [ApproveCallStatus, setApproveCallStatus] = React.useState("init");
  const [Items, Set_Items] = useState({});
  const [owner_Detail, set_owner_Detail] = useState({});
  const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);
  const [CoinName_Initial, set_CoinName_Initial] = useState("");
  const [CoinName, setCoinNames] = useState("");
  const [token_onwers_info, set_token_onwers_info] = useState({});
  const [ValidateError, Set_ValidateError] = useState({ TokenPrice: "" });
  const [priceoption, setPriceoption] = React.useState([]);
  const [StartDate, Set_StartDate] = useState("Select Start Date");
  const [EndDate, Set_EndDate] = useState("Select End Date");
  const [Clocktime, set_Clocktime] = useState("");
  const [EndClocktime, set_EndClocktime] = useState("");
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState("init");
  const [TokenPrice, Set_TokenPrice] = useState(0);
  const [MinimumBid, Set_MinimumBid] = useState(0);
  const [MintCallStatus, setMintCallStatus] = React.useState("init");
  const [currentOwner, setcurrentOwner] = React.useState("0");
  useEffect(() => {
    Set_ValidateError({});
    if (CoinName == "") {
      if (
        Wallet_Details.tokenAddress &&
        Wallet_Details.tokenAddress.length > 0
      ) {
        var bnbs = Wallet_Details.tokenAddress;

        if (bnbs.length > 0) {
          setPriceoption(bnbs);
        }
      }
    }
  }, [
    Wallet_Details.UserAccountAddr,
    PutOnSaleType,
    StartDate,
    EndDate,
    Clocktime,
    EndClocktime,
    PutOnSale,
    Wallet_Details.tokenAddress,
  ]);

  const ItemValidation = async () => {
    var ValidateError = {};
    var Chk_TokenPrice = TokenPrice;
    var CoinName_Initial =
      CoinName_Initial === undefined ? CoinName : CoinName_Initial;
  
    // Check if quantity is greater than balance
    if (suboutOfDateQuantity > currentOwner.balance) {
      ValidateError.Quantity = "Quantity is greater than balance";
    }
  
    // Check TokenPrice
    if (Chk_TokenPrice === "" || Chk_TokenPrice === undefined) {
      ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
    } else if (TokenPrice && TokenPrice.length > 12) {
      ValidateError.TokenPrice =
        "Enter valid price between 0.0001 to 1000000000000";
    } else if (TokenPrice < 0.0001 || TokenPrice > 1e12) {
      ValidateError.TokenPrice = "Enter valid price between 0.0001 to 1e12";
    }
  
    // Check Timed Auction details
    if (PutOnSale == true && PutOnSaleType == "TimedAuction") {
      if (Clocktime == "") {
        ValidateError.Clocktime = '"Start Clock Time " cant be a number';
      }
      if (EndClocktime == "") {
        ValidateError.EndClocktime = '"End Clock Time " cant be a number';
      }
      if (TokenPrice == "") {
        ValidateError.MinimumBid = '"Bid Price" must be a number';
      } else if (TokenPrice == 0) {
        ValidateError.MinimumBid = '"Price" must be greater than zero';
      } else if (TokenPrice < 0.0001 || TokenPrice > 1e18) {
        ValidateError.MinimumBid =
          '"Price" must be greater 0.001 & less Than 10^18';
      }
      if (CoinName == "") {
        ValidateError.MinimumBid = '"Currency or Token" must be required';
      }
    } else if (TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
      ValidateError.TokenPrice =
        '"Token Price" must be less than ' + TokenPrice_Initial;
    } else if (Chk_TokenPrice === 0) {
      ValidateError.TokenPrice = '"Token Price" must be greater than 0';
    } else if (isNaN(Chk_TokenPrice) === true) {
      ValidateError.TokenPrice = '"Token Price" must be a number';
    } else if (TokenPrice_Initial > 0 && TokenPrice >= TokenPrice_Initial) {
      ValidateError.TokenPrice =
        '"Token Price" must be less than ' + TokenPrice_Initial;
    }
  
    // Check Token Symbol
    if (TokenPrice_Initial === 0 || TokenPrice_Initial === null) {
      if (CoinName === "") {
        ValidateError.CoinName = '"Token Symbol" is required';
      } else if (CoinName === undefined) {
        ValidateError.CoinName = '" Token Symbol" is required';
      } else if (CoinName_Initial === undefined) {
        ValidateError.CoinName = '" Token Symbol" is required';
      }
    } else {
      // If TokenPrice_Initial is not 0 or null, delete TokenPrice error
      delete ValidateError.TokenPrice;
      Set_FormSubmitLoading("start");
    }
  
    // Check if there are any validation errors
    const hasErrors = !isEmpty(ValidateError);
  
    // If there are errors, add a generic "All Fields Required" message
    if (hasErrors) {
      ValidateError.allFieldsRequired = "All Fields Required";
      Set_FormSubmitLoading("error");
    } else {
      // If there are no errors, set loading state to "start"
      Set_FormSubmitLoading("start");
    }
  
    // Set validation errors
    Set_ValidateError(ValidateError);
  
    // Return the validation errors
    return ValidateError;
  };
  
  useImperativeHandle(ref, () => ({
    async PutOnSale_Click(
      item,
      tokenOwnerInfo,
      ownerdetail,
      suboutOfDateQuantity1
    ) {
      if (Wallet_Details.UserAccountAddr !== "") {
        var web3 = new Web3(Wallet_Details.providerss);
        var balance = await checkOtherPlatformDetais1155(
          item,
          ownerdetail,
          item.type,
          web3
        );
        var currOwner =
          item.tokenowners_current.length > 0 &&
          item.tokenowners_current.filter(
            (item) => item.tokenOwner === Wallet_Details.UserAccountAddr
          );
        setcurrentOwner(currOwner[0]);
        Set_TokenPrice(currOwner[0].tokenPrice);
        if (currOwner[0]?.balance > Number(balance)) {
          toast.warning("Nft purchase limit exceed", toasterOption);
          setTimeout(() => {
            history.push("/");
          }, 1000);
          return false;
        } else {
          var check = await orderApprovecheck(item, ownerdetail);
          if (check) {
            window.$("#putonsale").modal("show");

            setApproveCallStatus("done");
          } else {
            window.$("#price_item_modal").modal("show");
          }

          props.Set_HitItem(item);
          Set_Items(item);
          set_owner_Detail(ownerdetail);
          Set_TokenPrice_Initial(ownerdetail.tokenPrice);
          set_CoinName_Initial(ownerdetail.CoinName);
          setCoinNames(ownerdetail.CoinName);
          if (!isEmpty(tokenOwnerInfo)) {
            set_token_onwers_info(tokenOwnerInfo);
          } else {
            set_token_onwers_info({});
          }
          Set_ValidateError({});
        }
      } else {
        window.$("#connect_modal").modal("show");
        toast.warning("Connect Wallet");
      }
    },
  }));

  async function priceListFunction() {
    window.$("#price_item_modal").modal("hide");
    window.$("#putonsale").modal("show");
  }

  async function orderApprovecheck(item, tokenOwnerInfo) {
    if (Wallet_Details.providerss == null) {
      toast.warning("Connect Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    try {
      var MultiContract = new web3.eth.Contract(MULTIPLE, item.contractAddress);
      var status = await MultiContract.methods
        .isApprovedForAll(tokenOwnerInfo.tokenOwner, config.trade)
        .call();
      return status;
    } catch (e) {
      return false;
    }
  }

  const priceoptionfunc = async (e) => {
    Set_ValidateError({});
    set_CoinName_Initial(e.label);
    setCoinNames(e.label);
    Set_FormSubmitLoading("start");
  };

  const inputChange = (e) => {
    Set_ValidateError({});
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      Set_FormSubmitLoading("init");
      var value = e.target.value;

      switch (e.target.name) {
        case "TokenPrice":
          Set_TokenPrice(value);
          if (value !== "" && isNaN(value) === false && value > 0) {
            var weii = value * config.decimalvalues;
            var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
            var royal =
              (weii * item.tokenRoyality * config.decimalvalues) / 1e20;
            Set_YouWillGet(
              parseFloat((weii - per) / config.decimalvalues).toFixed(4)
            );
          }
          break;
        case "MinimumBid":
          Set_MinimumBid(value);
          if (value !== "" && isNaN(value) === false && value > 0) {
            var weii = value * config.decimalvalues;
            var per = (weii * Wallet_Details.Service_Fee_seller) / 1e20;
            var royal =
              (weii * item.tokenRoyality * config.decimalvalues) / 1e20;
            if (
              String(CoinName_Initial).toLowerCase() ===
              String(CoinName).toLowerCase()
            ) {
              Set_YouWillGet(
                parseFloat((weii - per) / config.decimalvalues).toFixed(2)
              );
            } else {
              Set_YouWillGet(
                parseFloat((weii - per) / config.decimalvalues).toFixed(4)
              );
            }
          }
          break;
        case "Quantity":
          setsuboutOfDateQuantity(value);
        default:
      }
    }
  };

  async function FormSubmit() {
    var ValidateError = {};
    var validation = await ItemValidation();

    if (isEmpty(validation)) {
      try {
        Set_FormSubmitLoading("start");

        if (!isEmpty(ValidateError)) {
          Set_FormSubmitLoading("error");
          toast.error(
            "Form validation error.Fix mistakes and submit again",
            toasterOption
          );
          return false;
        }
        if (Wallet_Details.providerss && CoinName != "") {
          try {
            var web3 = new Web3(Wallet_Details.providerss);
            if (web3) {
              var receipt = null;
              var handle = null;

              var CoursetroContract = new web3.eth.Contract(
                trade,
                config.trade
              );

              try {
                Set_FormSubmitLoading("processing");

                await CoursetroContract.methods
                  .orderPlace(
                    Items.tokenCounts,
                    web3.utils.toWei(String(TokenPrice)),
                    owner_Detail.contractAddress,
                    suboutOfDateQuantity,
                    "order"
                  )
                  .send({ from: Wallet_Details.Accounts })
                  .on("transactionHash", async (transactionHash) => {
                    handle = setInterval(async () => {
                      receipt = await getReceipt(web3, transactionHash);
                      clr1();
                    }, 8000);
                  });
              } catch (error) {
                Set_FormSubmitLoading("try");
                toast.error("Order Not Placed", toasterOption);
              }

              async function clr1() {
                if (receipt !== null) {
                  clearInterval(handle);
                  if (receipt.status === true) {
                    if (CoinName != "") {
                      Set_FormSubmitLoading("done");
                      var postData = {
                        tokenOwner: Wallet_Details.UserAccountAddr,
                        tokenCounts: Items.tokenCounts,
                        tokenPrice: TokenPrice,
                        blockHash: receipt.blockHash,
                        transactionHash: receipt.transactionHash,
                        owner: owner_Detail.tokenOwner,
                        CoinName: CoinName,
                        MinimumBid: 0,
                        Clocktime: null,
                        EndClocktime: null,
                        balance: owner_Detail.balance,
                        listquantity: suboutOfDateQuantity,
                      };

                      var Resp = await TokenPriceChange_update_Action(postData);
                      if (
                        Resp &&
                        Resp.data &&
                        Resp.data.RetType &&
                        Resp.data.RetType === "success"
                      ) {
                        toast.success(
                          "Order Placed successfully ",
                          toasterOption
                        );
                        history.push("/my-items");
                        window.$("#putonsale").modal("hide");
                      }
                    }
                  }
                }
              }
            }
          } catch (e) {}
        } else {
        }
      } catch (e) {}
    } else {
      toast.warn("Select Coin Type");
    }
  }

  async function ApproveCall() {
    var receiptt = null;
    var handlee = null;
    if (Wallet_Details.providerss == null) {
      toast.warning("Connect Wallet", toasterOption);
      return false;
    }
    var web3 = new Web3(Wallet_Details.providerss);
    var currAddr = Wallet_Details.UserAccountAddr;

    if (currAddr === "") {
      toast.warning("Connect Wallet", toasterOption);
      return false;
    }
    setApproveCallStatus("processing");
    try {
      var MultiContract = new web3.eth.Contract(
        MULTIPLE,
        Items.contractAddress
      );
      await MultiContract.methods
        .setApprovalForAll(config.trade, true)
        .send({
          from: owner_Detail.tokenOwner,
        })
        .on("transactionHash", async (transactionHash) => {
          if (transactionHash !== "") {
            handlee = setInterval(async () => {
              receiptt = await getReceipt(web3, transactionHash);
              clr();
            }, 8000);
          }
        });
    } catch (error) {
      toast.error("Approval Failed", toasterOption);
      setApproveCallStatus("tryagain");
    }
    async function clr() {
      if (receiptt != null) {
        clearInterval(handlee);
        toast.success("Approval Successfully", toasterOption);
        setApproveCallStatus("done");
      }
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="putonsale"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="change_price">
                {TokenPrice_Initial === 0 || TokenPrice_Initial === null
                  ? "Put On Sale"
                  : "Change Price"}
              </h5>
            </div>
            <div className="modal-body">
              <div className="approvetransaction top_heal">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-6 col-md-6">
                    <div className="putosale_imgcenter">
                      <div className="item_imgs kr_itemsimgs">
                        {Items &&
                          Items.image &&
                          (String(Items.image).split(".").pop() === "webp" ||
                            String(Items.image).split(".").pop() === "WEBP" ||
                            String(Items.image).split(".").pop() === "jpg" ||
                            String(Items.image).split(".").pop() === "JPG" ||
                            String(Items.image).split(".").pop() === "jpeg" ||
                            String(Items.image).split(".").pop() === "JPEG" ||
                            String(Items.image).split(".").pop() === "png" ||
                            String(Items.image).split(".").pop() === "PNG" ||
                            String(Items.image).split(".").pop() === "gif" ||
                            String(Items.image).split(".").pop() === "GIF") && (
                            <img
                              src={
                                Items.additionalImage
                                  ? Items.additionalImage === ""
                                    ? `${config.IPFS_IMG}/${Items.ipfsimage}`
                                    : `${config.Back_URL}/nftImg/${Items.tokenCreator}/${Items.additionalImage}`
                                  : `${config.IPFS_IMG}/${Items.ipfsimage}`
                              }
                              className="putonsale_img"
                              alt="imgae"
                              onError={`${config.IPFS_IMG}/${Items.ipfsimage}`}
                            />
                          )}
                      </div>
                      <p className="unclockpisce">
                        {TokenPrice_Initial === 0 || TokenPrice_Initial === null
                          ? `You are about to sell ${Items.tokenName}`
                          : `You are about to change price for ${Items.tokenName}`}
                      </p>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-6">
                    {TokenPrice_Initial > 0 ? (
                      <div className="formgroupsec">
                        <label>Price</label>
                        <div className="fixedpricedropdownflex">
                          <div className="fixedpries">
                            <input
                              className=""
                              type="text"
                              name="TokenPrice"
                              id="TokenPrice"
                              value={TokenPrice}
                              maxLength={config.maxLength}
                              onChange={(e) => inputChange(e)}
                            />
                            {ValidateError.TokenPrice && (
                              <span className="text-danger">
                                {ValidateError.TokenPrice}
                              </span>
                            )}
                            {ValidateError.CoinName && (
                              <span className="text-danger">
                                {ValidateError.CoinName}
                              </span>
                            )}
                          </div>

                          {CoinName != "" ? (
                            <Select
                              value={{ label: CoinName, value: CoinName }}
                              onChange={priceoptionfunc}
                              options={priceoption}
                              classNamePrefix="react-select kr_reactslct"
                            />
                          ) : (
                            <Select
                              defaultValue={priceoptionfunc[0]}
                              onChange={priceoptionfunc}
                              options={priceoption}
                              classNamePrefix="react-select kr_reactslct"
                            />
                          )}
                        </div>
                        <h4>
                          Service fee {Wallet_Details.Service_Fee_seller / 1e18}{" "}
                          {CoinName} You will receive {YouWillGet} {CoinName}{" "}
                        </h4>
                      </div>
                    ) : (
                      <>
                        <div className="putonsale">
                          <div></div>
                          <div className="toggleswitch"></div>
                        </div>

                        <div className={"tabbable-line"}>
                          <ul className="nav nav-tabs mt-3 mb-3">
                            <li className="active">
                              <a href="#fixedprice" data-toggle="tab">
                                <div className="fixedprice kr_fixedprice">
                                  <p>Fixed Price</p>
                                </div>
                              </a>
                            </li>
                          </ul>
                          {
                            <div className="tab-content">
                              <div className="tab-pane active" id="fixedprice">
                                <div className="fixedpricedropdown mb-4">
                                  <div className="formgroupsec">
                                    <label>Price</label>
                                    <div className="fixedpricedropdownflex">
                                      <div className="fixedpries">
                                        <input
                                          className=""
                                          type="text"
                                          name="TokenPrice"
                                          id="TokenPrice"
                                          maxLength={config.maxLength}
                                          onChange={(e) => inputChange(e)}
                                          placeholder="0"
                                        />
                                      </div>
                                      <div className="fixedpriesds">
                                        {CoinName != "" ? (
                                          <Select
                                            value={{
                                              label: CoinName,
                                              value: CoinName,
                                            }}
                                            onChange={priceoptionfunc}
                                            options={priceoption}
                                            classNamePrefix="react-select kr_reactslct"
                                          />
                                        ) : (
                                          <Select
                                            defaultValue={priceoptionfunc[0]}
                                            onChange={priceoptionfunc}
                                            options={priceoption}
                                            classNamePrefix="react-select kr_reactslct"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {ValidateError.TokenPrice && (
                                <span className="text-danger">
                                  {ValidateError.TokenPrice}
                                </span>
                              )}

                              {ValidateError.CoinName && (
                                <span className="text-danger">
                                  {ValidateError.CoinName}
                                </span>
                              )}
                            </div>
                          }
                        </div>
                      </>
                    )}

                    <div className="servicefee mb-3">
                      <h1 className="servicefeesec">Service Fee</h1>

                      <h3>
                        {TokenPrice > 0
                          ? ((Wallet_Details.Service_Fee_seller /
                              config.decimalvalues) *
                              TokenPrice) /
                            100
                          : Wallet_Details.Service_Fee_seller /
                            config.decimalvalues}
                        % {CoinName}
                      </h3>
                    </div>
                    <div className="servicefee mb-3">
                      <h1 className="servicefeesec">You will Get</h1>
                      <h3>
                        {" "}
                        {YouWillGet} {CoinName}
                      </h3>
                    </div>
                    <div className="startwalletbtns putonsale_botbtns">
                      <button
                        className="putonsale_startbtn"
                        onClick={true ? () => FormSubmit() : ""}
                      >
                        {FormSubmitLoading === "processing" && "In-Progress"}
                        {FormSubmitLoading === "init" && "Start"}
                        {FormSubmitLoading === "start" && "Start"}
                        {FormSubmitLoading === "done" && "Done"}
                        {FormSubmitLoading === "try" && "Try-Again"}
                        {FormSubmitLoading === "error" && "Form Error"}
                      </button>
                      <button
                        className="putonsale_cancelbtn"
                        data-bs-dismiss="modal"
                        disabled={FormSubmitLoading === "processing"}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-none"></div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="price_item_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="create_item_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">
                Follow Steps
              </h5>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  {ApproveCallStatus === "init" && (
                    <i
                      className="fas fa-check mr-3 pro_initial"
                      aria-hidden="true"
                    ></i>
                  )}
                  {ApproveCallStatus === "processing" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}
                  {ApproveCallStatus === "done" && (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  )}
                  {ApproveCallStatus === "tryagain" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}

                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <button
                    className={"buybtn"}
                    disabled={
                      ApproveCallStatus === "processing" ||
                      ApproveCallStatus === "done"
                    }
                    onClick={ApproveCall}
                  >
                    {ApproveCallStatus === "processing" && (
                      <i
                        className="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                        id="circle1"
                      ></i>
                    )}
                    {ApproveCallStatus === "init" && "Approve"}
                    {ApproveCallStatus === "processing" && "In-progress..."}
                    {ApproveCallStatus === "done" && "Done"}
                    {ApproveCallStatus === "tryagain" && "Try Again"}
                  </button>
                </div>
                <div className="media approve_media mt-3">
                  {MintCallStatus === "init" && (
                    <i
                      className="fas fa-check mr-3 pro_initial"
                      aria-hidden="true"
                    ></i>
                  )}
                  {MintCallStatus === "processing" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}
                  {MintCallStatus === "done" && (
                    <i
                      className="fas fa-check mr-3 pro_complete"
                      aria-hidden="true"
                    ></i>
                  )}
                  {MintCallStatus === "tryagain" && (
                    <i
                      className="fa fa-spinner mr-3 spinner_icon"
                      aria-hidden="true"
                    ></i>
                  )}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Listing the NFT</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <button
                    className={"buybtn"}
                    disabled={
                      ApproveCallStatus !== "done" ||
                      MintCallStatus === "processing" ||
                      MintCallStatus === "done"
                    }
                    onClick={priceListFunction}
                  >
                    {MintCallStatus === "processing" && (
                      <i
                        className="fa fa-spinner mr-3 spinner_icon"
                        aria-hidden="true"
                        id="circle1"
                      ></i>
                    )}
                    {MintCallStatus === "init" && "Start"}
                    {MintCallStatus === "processing" && "In-progress..."}
                    {MintCallStatus === "done" && "Done"}
                    {MintCallStatus === "tryagain" && "Try Again"}
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <Button
                className="bidbtn"
                data-bs-dismiss="modal"
                onClick={() => {
                  Set_TokenPrice(0);
                  Set_FormSubmitLoading("init");
                  setCoinNames("");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
